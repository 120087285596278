@font-face {
	font-family: Roboto-Regular;
	src: url(/static/media/Roboto-Regular.18d44f79.ttf);
}

@font-face {
	font-family: Roboto-Bold;
	src: url(/static/media/Roboto-Bold.7c181887.ttf);
}

@font-face {
	font-family: museo;
	src: url(/static/media/MuseoSansCyrl.woff.1a65aea0.ttf);
}
@font-face {
	font-family: museo700;
	src: url(/static/media/museosanscyrl-700.b4abf66b.ttf);
}

@font-face {
	font-family: museo900;
	src: url(/static/media/Museo900-Regular.4e1d39b1.otf);
}

body {
	margin: 0;
	font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	/*background: url('./imgs/background-02.jpg') no-repeat;*/
	background-size: cover;
	background-repeat: no-repeat;
	box-sizing: border-box;
}
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width:6px!important;
    height:6px!important;
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #acafbb;
  }
  ::-webkit-scrollbar-thumb:hover {
	background-color: #acafbb;
  }
  ::-webkit-scrollbar-track{
    background-color: transform
  }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	font-family: Roboto-Regular;
	cursor: pointer;
}

@-webkit-keyframes openBoleto {
	0% {
		opacity: 0.6;
		-webkit-transform: translateY(10%);
		        transform: translateY(10%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
}

@keyframes openBoleto {
	0% {
		opacity: 0.6;
		-webkit-transform: translateY(10%);
		        transform: translateY(10%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
	}
}

.container-tela-cpf-float-bottons {
	position: relative;
	max-width: 1320px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
  }
  
  .container-tela-cpf-float-bottons > .btn-whatsapp {
	background-color: #25d366;
	border-radius: 50%;
	padding: 12px;
	box-shadow: -2px 5px 9px -1px rgba(0, 0, 0, 0.4);
	position: fixed;
	bottom: 16px;
	align-self: right;
	border: none;
	margin-right: 16px;
	transition: all 0.5s ease;

  }
  
  .container-tela-cpf-float-bottons > .btn-whatsapp > img {
	width: 32px;
	margin-bottom: -5px;
  }
  


/** estilo barra */
.container-header {
  position: absolute;
  width: 100%;
  background: rgb(236, 112, 0);
  background: linear-gradient(
    90deg,
    rgba(236, 112, 0, 1) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 60%,
    rgba(236, 112, 0, 1) 100%
  );
}

.container-header > .header-content {
  display: flex;
  align-items: end;
  justify-content: space-between;

  max-width: 1320px;
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 8px;
}

.header-content > .btn-help {
  color: #fff;
  border: none;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  padding: 10px;
}

.header-content > .container-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;

  color: #484d4f;
  font-size: 12px;
}

.container-logo > .divider {
  background-color: #484d4f;
  width: 1px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.container-logo > .logo-itau {
  height: 40px;
}
.container-logo .logo-flex {
  height: 12px;
}

@media (max-width: 600px) {
  /** estilo barra */
  .container-header {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 10%,
      rgba(236, 112, 0, 1) 100%
    );
  }

  .container-header > .header-content {
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .header-content > .header-content-ancora {
    display: none;
  }

  .header-content > .btn-help {
    color: #fff;
    border: none;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    padding: 10px;
  }
}

.container {
  min-height: 100vh;
  background-image: url(/static/media/plano-fundo-splash.06f31f6b.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container > .container-body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-body > .body-content {
  color: #fff;
  max-width: 885px;
  text-align: center;
  margin-bottom: -130px;
}
.body-content > h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.body-content > p {
  font-size: 28px;

  text-align: center;
  font: normal normal normal 32px;
  letter-spacing: 0px;
  opacity: 1;
}

@media (max-width: 480px) {
  .body-content > h1 {
    font-size: 25px;
  }

  .body-content > p {
    font-size: 20px;
  }
}

.lds-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0px;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.7);
}

.lds-ellipsis {
	display: inline-block;
	position: absolute;
	width: 64px;
	height: 64px;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.lds-ellipsis div {
	position: absolute;
	top: 27px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: #EC7000;
	-webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
	        animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 6px;
	-webkit-animation: lds-ellipsis1 0.6s infinite;
	        animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 6px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 26px;
	-webkit-animation: lds-ellipsis2 0.6s infinite;
	        animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 45px;
	-webkit-animation: lds-ellipsis3 0.6s infinite;
	        animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes lds-ellipsis1 {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	100% {
		-webkit-transform: scale(0);
		        transform: scale(0);
	}
}

@-webkit-keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}

	100% {
		-webkit-transform: translate(19px, 0);
		        transform: translate(19px, 0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}

	100% {
		-webkit-transform: translate(19px, 0);
		        transform: translate(19px, 0);
	}
}

.container-tela-cpf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  background: url(/static/media/plano-fundo-consulta-cpf.f34942b9.jpg) no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container-tela-cpf-information {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
}

.container-tela-cpf-information > .container-tela-cpf-benefits {
  margin-top: 24px;
  margin-bottom: 16px;
  padding-left: 36px;
  padding-right: 36px;
}
.container-tela-cpf-benefits > h1 {
  margin: 0;
}
.container-tela-cpf-benefits > p {
  max-width: 770px;
  font-size: 24px;
  color: #707070;
}
.container-tela-cpf-benefits-items {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.container-tela-cpf-benefits-items .container-group-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: start;
}

.container-tela-cpf-benefits-items .benefits-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 230px;
  padding: 16px;
}

.benefits-item div {
  margin-bottom: 16px;
}

.benefits-item div img {
  width: 110px;
}
.benefits-item p {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
  padding-bottom: 20px;
}
.benefits-item span {
  font-size: 14px;
  margin-bottom: 16px;
}

.container-tela-cpf-information > .container-tela-cpf-phases {
  margin-top: 24px;
  margin-bottom: 16px;
  padding-left: 36px;
  padding-right: 36px;
}
.container-tela-cpf-phases > h1 {
  margin: 0;
}
.container-tela-cpf-phases > p {
  font-size: 24px;
  color: #707070;
}
.container-tela-cpf-phases-items {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}
.container-tela-cpf-phases-items > .phases-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 330px;
  padding: 16px;
  position: relative;
}

.phases-item div {
  border-radius: 30px;
  border: 3.5px solid #ec7000;
  padding: 16px;
  margin-top: 20px;
}

.phases-item img {
  width: 100%;
}
.phases-item p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
  color: #ec7000;
}
.phases-item span {
  font-size: 14px;
  margin-bottom: 16px;
}

.container-tela-cpf-whatsapp-session {
  background: #e8e8e8;
  margin-bottom: 50px;
}
.container-tela-cpf-whatsapp-session > .container-whatsapp-session {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  color: #252220;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.container-whatsapp-session > .container-whatsapp-session-img {
  border-radius: 40px;
  max-width: 437px;
  margin: 18px;

  height: auto;
  position: relative;
}
.container-whatsapp-session-img img {
  object-fit: cover;
  width: 100%;
}

.container-whatsapp-session > .container-whatsapp-session-text {
  margin: 16px;
  max-width: 500px;
}
.container-whatsapp-session-text > p {
  padding-bottom: 24px;
}

.container-whatsapp-session-text > h1 {
  margin-top: 0;
}

.btn-negociar-whatsapp {
  background-color: #25d366;
  color: #fff;
  font-weight: bold;
  padding: 12px 32px;
  text-decoration: none;
  border-radius: 8px;
  border: none;
  white-space: nowrap;
}

.container-tela-cpf-float-bottons > .btn-negociar {
	background-color: #ec7000;
	border-radius: 8px;
	padding: 12px 32px;
	box-shadow: -2px 5px 9px -1px rgba(0, 0, 0, 0.4);
	position: fixed;
	bottom: 16px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	margin: 0 auto;
	border: none;
	color: white;
	font-weight: bold;
	font-size: 16px;
	transition: all 0.5s ease;
	opacity: 0;
  }

.container-tela-cpf-items {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  -webkit-animation: openBoleto 2s;
          animation: openBoleto 2s;
  max-width: 1320px;
}

.container-tela-cpf-description {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  height: 100%;
  color: white;
  max-width: 440px;
  font-size: 25px;
  padding: 16px;
  font-weight: 100;
}

.tela-cpf-description-title {
  font-size: 30px;
  padding-bottom: 10px;
}
.tela-cpf-description-text {
  font-size: 25px;
}

.container-tela-cpf-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: #707070;
  padding-top: 16px;
  padding-bottom: 16px;
}

.container-tela-cpf-footer > .content-footer {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  flex-wrap: wrap;
}

.container-tela-cpf-footer .footer-logo-flex {
  padding: 10px;
}
.container-tela-cpf-footer .footer-logo-flex > img {
  height: 50px;
}
.container-tela-cpf-footer .footer-logo-flex > p {
  white-space: nowrap;
  font-size: 12px;
}
.container-tela-cpf-footer .content-footer > p {
  max-width: 450px;
  font-size: 14px;
  padding: 10px;
}

.container-tela-cpf-img {
  background: white;
  border-radius: 15px;
  min-height: 100%;
  display: flex;
}

.container-tela-cpf-forms {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  padding: 25px 30px;
  box-shadow: -2px 5px 9px -1px rgb(0 0 0 / 40%);
  max-width: 350px;
}

.container-tela-cpf-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  grid-gap: 16px;
  gap: 16px;
  grid-gap: 16px;
}

.form-telacpf {
  border: solid 2px #b4b2b0;
  color: #707070;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container-tela-cpf-text {
  text-align: left;
}

.cabecalho-telacpf div {
  text-align: left;
  color: #252220;
}

.cabecalho-telacpf span {
  border-bottom: 5px solid #ec7000;
}

.tela-cpf-nao-conheco {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  color: #707070;
  text-align: center;
  text-decoration: underline;
  background-color: transparent;
  width: 100%;
  white-space: nowrap;
}

.tela-cpf-acessar {
  font-size: 16px;
  font-family: Roboto-Regular;
  border: 1px solid #ec7000;
  outline: none;
  color: #ec7000;
  border-radius: 5px;
  padding: 12px 16px;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  white-space: nowrap;
}

.cpf {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  background: transparent;
  text-align: right;
  color: #707070;
}

.cpf::-webkit-input-placeholder {
  font-family: Roboto-Regular;
  color: #707070;
}

.cpf:-ms-input-placeholder {
  font-family: Roboto-Regular;
  color: #707070;
}

.cpf::placeholder {
  font-family: Roboto-Regular;
  color: #707070;
}

.cnpj {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  background: transparent;
  text-align: right;
  color: #707070;
}

.label-cpf {
  font-family: Roboto-Regular;
}

.label-cnpj {
  font-family: Roboto-Regular;
}

.Button-cpf {
  font-family: museo;
  background-color: #ec7000;
  text-align: center;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  padding: 12px 16px;
  width: 100px;
}

.row {
  -webkit-animation: openBoleto 2s;
          animation: openBoleto 2s;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col {
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
  width: 100%;
  *zoom: 1;
  *display: inline;
}

.col-tam-img {
  width: 85%;
}

.col-tam-txt {
  width: 75%;
}

.input {
  border: solid 1px whitesmoke;
  border-radius: 50px;
  color: white;
  width: 68%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 1em;
  align-items: center;
  margin-bottom: 2%;
  font-family: museo700;
}

::-webkit-input-placeholder {
  color: white;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}

:-ms-input-placeholder {
  color: white;
}

.label_cnpj {
  font-size: 0.8em;
}

.ajust-img-aling {
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-title {
  width: 100%;
}

.border-barra-title {
  height: auto;
  width: 70%;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 20%;
  border: 2px solid white;
  border-radius: 15px;
  background-color: white;
}

.textoCPF {
  color: #707070;
  font-family: Roboto-Regular;
  font-size: 1em;
  word-break: normal;
}
.texto-cpf-description {
  color: #707070;
  font-family: Roboto-Regular;
  font-size: 0.85em;
  word-break: normal;
}

.modal {
  position: absolute;
  width: 400 !important;
}

.cabecalho-telacpf {
  text-align: center;
  color: white;
  font-family: Roboto-Bold;
  font-weight: 900;
  font-size: 1.7em;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
}

@media only screen and (min-width: 720px) {
  .container-tela-cpf {
    height: 100vh;
    min-height: 450px !important;
  }

  .container-tela-cpf-items {
    width: 100%;
  }

  .container-tela-cpf-footer {
    width: 100%;
  }

  .container-tela-cpf-img {
    border-radius: 15px;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin: 10px;
  }

  .container-tela-cpf-forms {
    min-width: 320px;
    width: 380px;
    margin: 0 16px -30px 16px;
  }

  .form-telacpf {
  }

  .tela-cpf-nao-conheco {
  }

  .tela-cpf-acessar {
  }

  .cpf {
    font-size: 1.2em;
    width: 60px;
  }

  .cpf::-webkit-input-placeholder {
    letter-spacing: 0px;
  }

  .cpf:-ms-input-placeholder {
    letter-spacing: 0px;
  }

  .cpf::placeholder {
    letter-spacing: 0px;
  }

  .cnpj {
    font-size: 1em;
    width: 35px;
  }

  .label-cpf {
    font-size: 1.2em;
  }

  .label-cnpj {
    font-size: 1em;
  }
}

@media only screen and (max-width: 720px) {
  .container-tela-cpf {
    min-height: 610px !important;
  }

  .container-tela-cpf-items {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    -webkit-animation: openBoleto 2s;
            animation: openBoleto 2s;
  }

  .container-tela-cpf-footer {
    width: 100%;
  }

  .container-tela-cpf-img {
    border-radius: 15px;
    height: 100%;
    padding: 20px;
    margin: 10px;
  }

  .container-tela-cpf-description {
    max-width: 410px;
  }

  .container-tela-cpf-forms {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -230px;
  }

  .form-telacpf {
  }

  .tela-cpf-nao-conheco {
  }

  .tela-cpf-acessar {
  }

  .cpf {
    font-size: 1.2em;
    width: 60px;
  }

  .cpf::-webkit-input-placeholder {
    letter-spacing: 0.1px;
  }

  .cpf:-ms-input-placeholder {
    letter-spacing: 0.1px;
  }

  .cpf::placeholder {
    letter-spacing: 0.1px;
  }

  .cnpj {
    font-size: 1em;
    width: 35px;
  }

  .label-cpf {
    font-size: 1.2em;
  }

  .label-cnpj {
    font-size: 1em;
  }

  .container-tela-cpf-information {
    margin-top: 200px;
  }
}

@media (max-width: 430px) {
  .container-tela-cpf {
    min-height: 760px !important;
  }
}

@media (max-width: 400px) {
  .container-tela-cpf-forms {
    margin-top: 4px !important;
  }
}

@media (max-width: 480px) {
  .tela-cpf-description-title {
    font-size: 25px;
  }
  .tela-cpf-description-text {
    font-size: 20px;
  }
  
  .container-tela-cpf-forms {
    margin-bottom: -280px;
  }
  .modal-buttons-options {
    display: flex;
    flex-direction: column;
  }
  .modal-buttons-options > button {
    width: 100%;
    margin-top: 8px;
  }
  .container-tela-cpf-information {
    margin-top: 245px;
  }

  .container-tela-cpf-benefits > h1 {
    font-size: 25px;
  }
  .container-tela-cpf-benefits > p {
    font-size: 20px;
  }

  .container-tela-cpf-phases > h1 {
    font-size: 25px;
  }
  .container-tela-cpf-phases > p {
    font-size: 20px;
  }
  .container-whatsapp-session-text > h1 {
    font-size: 25px;
  }

  .MuiDialog-paperWidthSm-9 {
    margin: 10px !important;
  }

  .MuiDialogContentText-root-84 {
    white-space: normal !important;
    text-align: center !important;
  }
}

@media (min-width: 480px) {
  .col-2 {
    width: 30%;
  }
  .col-tam-txt {
    width: 30%;
    font-size: 19px;
  }
  .ajust-img-aling {
    display: flex;
    justify-content: flex-end !important;
    margin-right: 15px;
    align-items: flex-end;
  }
}

@media (max-width: 495px) {
  .container-tela-cpf-benefits-items .benefits-item {
    max-width: 260px !important;
  }
}

@media (max-width: 600px) {
  .input {
    width: 100%;
  }
  .Container-cpf {
    justify-content: flex-start;
  }
  .border-barra-title {
    margin-top: 35%;
  }
  .container-form-telacpf {
    width: 100%;
  }

  .container-tela-cpf-benefits-items .benefits-item {
    max-width: 180px;
  }

  .benefits-item p {
    padding-bottom: 0;
  }
}

@media (max-width: 1200px) {
  .input {
    width: 90%;
  }
  .container-form-telacpf {
    width: 90%;
  }
}

@media (max-width: 1320px) {
  .input {
    width: 90%;
  }

  .button-cpf-cliente {
    margin-left: -38%;
  }

  .container-form-telacpf {
    width: 90%;
  }

  .button-cpf-cliente {
    margin-left: -38%;
  }
}

@media (max-height: 747px) {
  .container-tela-cpf-forms {
    margin-top: 45px;
  }
}

@media (max-width: 575.98px) {
  .container-tela-cpf-float-bottons > .btn-negociar {
    left: 16px !important;
    -webkit-transform: none;
            transform: none;
  }
  .container-tela-cpf {
    min-height: 660px !important;
  }

  .container-tela-cpf-buttons {
    flex-direction: column;
  }
}

@media (max-width: 991.98px) {
  .tela-cpf-description-title {
    font-size: 27px;
  }
  .tela-cpf-description-text {
    font-size: 22px;
  }
}

.logo-erro {
  width: 45%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.container-erro {
  background-color: #f16112;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.container-erro-content {
  background-color: #fff;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.container-erro-items {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container-erro-label {
  color: #252220;
  font-weight: bold;
  font-size: 1.5em;
}

.container-erro-content > .container-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;

  color: #484d4f;
  font-size: 12px;
}

.container-erro-content > .container-logo > .divider {
  background-color: #484d4f;
  width: 1px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.container-erro-content > .container-logo > .logo-itau {
  height: 65px;
}
.container-erro-content > .container-logo .logo-flex {
  height: 25px;
}
.container-erro-content > .container-logo p {
    font-size: 16px;
}

.container-error-modal {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-error-modal {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    width: 90%;
    max-width: 350px;
    min-height: 300px;
    max-height: 80vh;
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    -webkit-animation: open 0.4s ease-in-out;
            animation: open 0.4s ease-in-out;
}

.error-modal-message {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    color: #252220;
}

.error-modal-button {
    border: none;
    outline: none;
    min-width: 125px; 
    text-align: center;
    background: #EC7000;
    font-family: museo700;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    padding: 12px 16px;
}

.error-modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-modal-image img {
    display: flex;
    height: 75px;
}
.error-modal-title {
    font-size: 35px;
    color: #EC7000;
    margin-top: -36px;
}

@-webkit-keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }
.container-detalhes-modal {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
}

p {
    margin: 2px;
}

.wrap-detalhes-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90%;
    max-width: 450px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 300px;
    max-height: 80vh;
    margin: auto;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    -webkit-animation: open 0.4s ease-in-out;
            animation: open 0.4s ease-in-out;
    overflow-y: scroll;
    height: fit-content;
}

.wrap-detalhes-modal::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
.wrap-detalhes-modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal::-webkit-scrollbar-thumb {
    background: rgb(133, 131, 128); 
    border-radius: 10px;
}


.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: #EC7000;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

.title-mensagem-detalhes-acordo {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 1.0em;
    text-align: center;
    margin: 5px 0 5px 0;
}

@media (max-width: 350px) {
    .card-detalhe-parcela>span {
        font-size: 23px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}

.wrap-detalhes-modal-cards {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.wrap-detalhes-modal-cards::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
.wrap-detalhes-modal-cards::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px whitesmoke; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal-cards::-webkit-scrollbar-thumb {
    background: #737372; 
    border-radius: 10px;
}

@-webkit-keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }
.card-detalhe-parcela {
    display: flex;
    height: 50px;
    margin-top: 15px;
    flex-direction: row;
    border: 1px solid #414141;
    justify-content: space-between;
    padding: 0px 5%;
    border-radius: 10px;
    box-shadow: 0px 3px 2px 0px rgba(65, 65, 65, 0.33);
}
.card-detalhe-parcela:first-child {
    margin-top:0;
}

.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: #EC7000;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

@media (max-width: 520px) {

    .card-detalhe-parcela-numero>span:first-of-type {
        margin: auto;
        margin-right: 0;
        width: 45px;
    }

    .card-detalhe-parcela-numero>span:last-of-type {
        font-size: 20px;
        font-weight: bold;
        color: #EC7000;
        line-height: 50px;
    }

    .card-detalhe-parcela>span {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}
.tela-inicial-text-boleto {
  color: #ec7000;
  font-family: Roboto-Regular;
  font-weight: 600;
  font-size: 2em;
  padding: 5%;
  padding-bottom: 0;
  text-align: left;
}

.tela-inicial-sifrao {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50px;
}

.botao-gerar-boleto {
  background-color: #737372;
  margin-top: 5px;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 350px;
  text-align: center;
  word-break: unset;
  border: none;
  outline: none;
  color: white;
  border-radius: 15px;
}

.Button-detalhes {
  background-color: #ec7000;
  border-color: #ec7000;
  margin-top: 12px;
  border: 57px;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 350px;
  border: none;
  outline: none;
  color: white;
  border-radius: 15px;
}

.impressora {
  margin-left: -4%;
  width: 108% !important;
  position: relative;
  z-index: 0;
}
.flex-logo {
  width: 35% !important;
  padding: 5%;
  position: relative;
  z-index: 0;
}

.checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}

.Container-inicial {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.Container-inicial {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.35) 0%,
       rgba(236, 110, 0, 0.5) 70%
    ),
    url(/static/media/background-02.574b429a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100vh;
}

.Container-final {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
}

.Container-final {
  background: url(/static/media/background-02.574b429a.jpg);
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0;
}

.Cabecalho-white {
  text-align: center;
  color: white;
  font-family: Roboto-Bold;
  font-weight: bold;
  font-size: 1.7em;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
}

.Cabecalho {
  text-align: center;
  color: white;
  font-family: Roboto-Bold;
  font-weight: bold;
  font-size: 1.7em;
  padding-top: 0;
  padding-bottom: 0;
}

.sifrao {
  width: 22px !important;
}

.wholePaper {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-bottom: 25px;
  -webkit-animation: openBoleto 2s;
          animation: openBoleto 2s;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 10px;
}

.PapelTop {
  box-sizing: border-box;
  background-color: white;
  border-radius: 15px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background-image: linear-gradient(
    to right,
    black 65%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
  display: flex;
  flex-direction: column;
}

.MiniFlex {
  width: 40%;
  padding-top: 10%;
  padding-left: 15px;
}

.LinhaUm {
  display: flex;
  justify-content: space-between;
  padding: 5% 5% 0px 5%;
}

.LinhaUm > * {
  text-align: center;
}

.ajust {
  margin-top: 60px;
}

.Title-gerado {
  margin-top: 5px;
  font-size: 1em;
  text-align: left;
  font-family: museo700;
  white-space: nowrap;
}

.Title {
  font-size: 1em;
  font-family: museo700;
  white-space: nowrap;
}

.Title1 {
  font-size: 1.1em;
  font-family: museo700;
  white-space: nowrap;
}

.Title2 {
  font-size: 1.1em;
  text-align: left;
  margin-top: -10%;
  font-family: museo700;
  white-space: nowrap;
}

.Title3 {
  margin-top: 5px;
  font-size: 1em;
  font-family: museo700;
  word-break: unset;
  word-wrap: unset;
  overflow-wrap: unset;
  -webkit-hyphens: unset;
  -ms-hyphens: unset;
  hyphens: unset;
}

.subTitleSpace {
  font-weight: 100;
  color: black;
  font-size: 1em;
  white-space: nowrap;
  overflow-x: hidden;
  margin-top: 17.3px;
  margin-bottom: 30px;
  font-family: museo;
}

.subTitleSpace2 {
  font-weight: 100;
  color: black;
  font-size: 1em;
  word-break: break-all;
  margin-top: calc(12.5% + 10px);
  margin-bottom: 30px;
  text-align: left;
  font-family: museo;
}

.TitleSpace {
  font-size: 1em;
  padding-top: 20px;
  white-space: nowrap;
  font-family: museo700;
}

.Texto {
  font-weight: 100;
  color: black;
  font-size: 1em;
  white-space: nowrap;
  font-family: museo;
  height: 45px;
}

.Linha {
  display: flex;
  justify-content: space-around;
  padding: 5%;
  align-items: center;
}

.Button {
  font-family: museo;
  font-size: 0.8em;
  background-color: #737372;
  padding-top: 5%;
  padding-bottom: 5%;
  border: none;
  outline: none;
  color: white;
}

.Button,
.Button-White {
  font-family: museo700;
  background-size: cover;
  padding-top: 1%;
  padding-bottom: 1%;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 8%;
  font-weight: bold;
}

.Button-White {
  background-color: #383838;
  color: white;
  font-size: 15px;
}

.Button,
.Button-Black {
  font-family: museo700;
  background-size: cover;
  padding-top: 1%;
  padding-bottom: 1%;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 8%;
  font-weight: bold;
}

.Button-Black {
  background-color: #383838;
  color: white;
  font-size: 15px;
}

.Button2 {
  font-family: museo;
  font-size: 0.8em;
  background-color: #737372;
  padding: 5%;
  border: none;
  text-align: center;
  outline: none;
  color: white;
  border-radius: 5px;
}

.center-center {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}
.fs-35 {
  font-size: 35px;
  color: #ec7000;
}

.ajustModal {
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  max-width: 350px;
}

.PapelBottom {
  background-color: white;
  border-radius: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  min-height: 150px;
}

.Bottom {
  text-align: center;
}

.TitleBottom {
  font-family: museo900;
  font-size: 1em;
  text-align: center;
}

.TitleBottom2 {
  font-family: Roboto-Regular;
  font-size: 1.3em;
}

.labelButton {
  text-align: center;
  color: white;
  font-size: 1em;
  margin-top: 2%;
}

.labelButtonBig {
  color: black;
  font-family: museo900;
  font-size: 0.7em;
  margin-top: 10%;
  margin-bottom: 10px;
}

.labelSMS {
  font-family: museo;
  color: white;
  font-size: 1.4em;
  padding: 10px 10px;
  padding-bottom: 10px;
  text-align: center;
}

.labelNaoEspere {
  color: #ec7000;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -5px;
}

.div-select {
  width: 250px;
  overflow: hidden;
}

.class-select {
  background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-select.gif)
    no-repeat #354880;
  background-position: 205px center;
  width: 270px;
  height: 48px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding: 13px 20px 13px 12px;
  color: #fff;
  text-indent: 0.01px;
  text-overflow: "";
}

.select-parcela,
.select-vencimento {
  background-color: #ec7000;
  height: 30px;
  width: 90%;
  font-size: 0.8em;
  color: white;
  border-radius: 5px;
  outline: none;
  font-family: museo700;
  margin-top: 5px;
  margin-left: -1px;
  box-sizing: border-box;
  -webkit-appearance: none;
  background: url(/static/media/seta.87e027b4.svg) no-repeat right center #ec7000;
  background-size: 12px 12px;
  background-position-x: 95%;
  padding-left: 5px;
  padding-right: 20px;
  border: none;
  cursor: pointer;
}

.select-vencimento {
  text-align: right;
}

.Boleto {
  width: 30%;
}

.tela-inicial-sifrao {
  width: 20px;
}

.itensBottom {
  width: 60%;
  text-align: right;
}
.textoBottom {
  font-size: 0.7em;
  font-family: Roboto-Regular;
  text-align: right;
}

/* Mobiles */
@media (max-width: 569px) {
  .Cabecalho {
    text-align: left;
  }
  .wholePaper {
    width: 80%;
  }
  .PapelBottom {
    width: 100%;
  }

  .PapelTop {
    width: 100%;
  }

  .tela-inicial-sifrao img {
    width: 15px;
  }

  .impressora {
    width: 15px;
  }

  .labelButtonBig {
    font-size: 11px !important;
  }
  .Button,
  .Button-White {
    font-size: 13px !important ;
    width: 87%;
  }

  .Cabecalho-white {
    width: 90%;
  }
  .botao-gerar-boleto {
    width: 100%;
    height: 35px;
  }

  .Button-detalhes {
    width: 100%;
    height: 35px;
  }

  .textoBottom {
    font-size: 0.55em;
    font-family: Roboto-Regular;
    text-align: right;
  }

  .select-parcela,
  .select-vencimento {
    font-size: 0.7em;
  }
}

@media only screen and (max-width: 320px) {
  .MuiModal-root-15 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1300;
    position: fixed;
    width: 105%;
  }
  .tela-inicial-text-boleto {
    font-size: 1.5em;
  }
  .Title2 {
    font-size: 0.9em;
  }
  .Title1 {
    font-size: 0.9em;
  }
  .Title {
    font-size: 0.9em;
  }

  .TitleSpace {
    font-size: 0.9em;
  }

  .Title3 {
    font-size: 0.9em;
  }
  .textoBottom {
    font-size: 0.5em;
    font-family: Roboto-Regular;
    text-align: right;
  }
  .select-parcela,
  .select-vencimento {
    font-size: 0.7em;
  }
}

@media only screen and (orientation: portrait) {
  .Boleto {
    width: 30%;
    margin-right: 5%;
  }
}

@media only screen and (orientation: landscape) {
  .Boleto {
    width: 30%;
    margin-right: 2%;
  }
}

.tela-inicial-boleto-direita {
  text-align: right;
  width: 45%;
}

.tela-inicial-boleto-esquerda {
  width: 45%;
  text-align: left;
}

.tela-inicial-selects {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5% 5% 5%;
}

.tela-inicial-vencimentos {
  width: 50%;
  text-align: right;
}

.tela-inicial-parcelas {
  width: 50%;
}

.segunda-via {
  background-color: #fff;
  border-color: #ec7000;
  border-style: solid;
  border-radius: 8px;
  width: 100%;
  margin: 10px 0px;
}

a.bt-pdf {
  -webkit-appearance: button;
  appearance: button;

  text-decoration: none;
  color: #fff;
  background-color: #7c590e;
  margin: 6px;
  padding: 8px;
  /* width: 80%; */
  border-radius: 6px;
}

.linha-segunda-via {
  font-size: small;
  padding: 2px 20px;
}

.linha-copy-link {
  -webkit-appearance: unset;
          appearance: unset;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-self: center;
  justify-content: space-between;
  margin: 4px 30px;
}

.inativo {
  display: none;
}

.cabecalho-boleto {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.div-setas {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
}

.seta-pra-direita {
  width: 0px;
  height: 0px;
  border: 15px solid transparent;
  float: left;
  margin-right: 20px;
  cursor: pointer;
}

.seta-pra-esquerda {
  width: 0px;
  height: 0px;
  border: 15px solid transparent;
  float: left;
  margin-left: 20px;
  cursor: pointer;
}

div.seta-pra-direita {
  border-right-color: #ec7000;
}

div.seta-pra-esquerda {
  border-left-color: #ec7000;
}

.container-tela-cpf-float-bottons {
  position: relative;
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.container-pesquisa-satisfacao {
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    overflow: hidden;
}

.none{
    display: none;
}
.div-pesquisa {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 280px;
    padding: 20px;
    min-height: 325px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
}

.div-pesquisa-text {
    display: flex;
    flex-direction: column;
}
.div-pesquisa-text>span:first-of-type {
    color: #EC7000;
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 15px;
}

.div-pesquisa-text>span:last-of-type {
    color: #414141;
    font-size: 17px;
}

.div-pesquisa-button>button {
    height: 40px;
    width: 200px;
    text-align: center;
    margin-bottom: 0px;
    background-color: #EC7000;
    color: white;
    border: none;
    border-radius: 15px;
    font-size: 18px;
}

.div-pesquisa-button>button:disabled {
    background-color: lightgray;
}

.div-close {
    width: 100%;
    text-align: right;
    cursor: pointer;
}
.div-close img {
    width: 15px;
}

.close {
    width: 6mm;
}

/* bloco das estrelas */

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 3.2em;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: '★ ';
}

.rate>input:checked~label {
    color: #EC7000;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #EC7000;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #EC7000;
}
.LinhaUm-boleto-gerado {
	display: flex;
	flex-direction: column;
	padding: 5%;
}

.linha2-boleto-gerado {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.subTitle-boleto-gerado {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	overflow-x: hidden;
	font-family: museo;
}

.botao-copiar-boleto-gerado {
	background-color: #EC7000;
	padding-top: 5%;
	padding-bottom: 5%;
	border: none;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 5px;
}

.textoBottom-boleto-gerado {
	font-size: 0.7em;
	font-family: Roboto-Regular;
	text-align: right;
}

.Title-gerado-boleto {
	font-size: 1em;
	text-align: left;
	font-family: museo700;
	white-space: nowrap;
}

.Container-boleto {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.itensBottom {
	width: 55%;
	text-align: right;
}

.Container-boleto {
	background: url(/static/media/background-02.574b429a.jpg) center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 100vh;
}

.Button-boleto-gerado {
	/* font-family: museo; */
	background-color: #EC7000;
	margin-top: 5px;
	margin-bottom: 15px;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	text-align: center;
	word-break: unset;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
}

@media only screen and (max-width: 565px) {

	.Button-boleto-gerado {
		width: 90%;
	}

	.LinhaUm-boleto-gerado {
		display: flex;
		flex-direction: column;
		padding: 5%;
	}

	.linha2-boleto-gerado {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.subTitle-boleto-gerado {
		font-weight: 100;
		color: black;
		font-size: 1em;
		white-space: nowrap;
		overflow-x: hidden;
		font-family: museo;
	}

	.botao-copiar-boleto-gerado {
		padding-top: 5%;
		padding-bottom: 5%;
		border: none;
		outline: none;
		text-align: center;
		color: white;
		border-radius: 5px;
		font-size: 0.7em;
	}

	.textoBottom-boleto-gerado {
		font-size: 0.70em;
		font-family: Roboto-Regular;
		text-align: right;
	}

	.itensBottom {
		width: 60%;
		text-align: right;
	}
	@media (max-width: 400px) and (min-height: 650px) {
		.itensBottom {
			width: 65%;
			text-align: right;
		}
	
		.textoBottom-boleto-gerado {
			font-size: 0.60em;
			font-family: Roboto-Regular;
			text-align: right;
		}
	}
}
@media only screen and (max-width: 360px) {
	.Container-boleto {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: left;
	}

	.Container-boleto {
		background: url(/static/media/background-02.574b429a.jpg);
		background-size: cover;
		position: static;
		position: initial;
		width: 100%;
		height: 100%;
	}
}

@media only screen and (max-width: 475px) {
	.Container-boleto {
		width: 100%;
		height: -1%;
		position: static;
		position: initial;
	}

}

#inativo {
	display: none;
}
.container-tela-pdf {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-around;
	align-items: center;
}

.tela-pdf-logo {
	display: flex;
	justify-content: center;
}

.tela-pdf-logo img {
	width: 15%;
	min-width: 250px;
}

.tela-pdf-iframe {
	display: flex;
  justify-content: center;
  background: gray;
  min-width: 70%;
}

.tela-pdf-iframe iframe{
  border: none;
  outline: none;
  min-height: 65vh;
  width: 100%;
}

.tela-pdf-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tela-pdf-button button {
	text-align: center;
	background-color: #fab628;
  width: 250px;
  border: 57px;
	text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
	border: none;
	outline: none;
	color: white;
  border-radius: 10px;
  font-family: museo700;
}

@media only screen and (max-width: 720px) and (orientation: landscape) {

  .tela-pdf-iframe {
    width: 90%;
  }

  .tela-pdf-iframe iframe{
    height: 300px;
  }

  .tela-pdf-logo {
    margin: 25px;
  }

  .tela-pdf-button button {
    margin: 25px;
  }
}
@media only screen and (max-width: 720px) and (orientation: portrait) {

  .tela-pdf-iframe {
    width: 90%;
  }

}


.container-tela-desconhece {
    background-color: #f16112;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.container-tela-desconhece-content {
    background-color: #fff;
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tela-desconhece-logo {
    width: 45%;
    min-width: 250px;
}

.tela-desconhece-items {
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -35px;
    height: 76px;
}

.tela-desconhece-success {
    display: flex;
    justify-content: center;
}

.tela-desconhece-success img {
    width: 125px;
}

.tela-desconhece-text {
    color: #252220;
    font-size: 1.4em;
}

.container-tela-desconhece-content > .container-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  
    color: #484d4f;
    font-size: 12px;
  }
  
  .container-tela-desconhece-content > .container-logo > .divider {
    background-color: #484d4f;
    width: 1px;
    height: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .container-tela-desconhece-content > .container-logo > .logo-itau {
    height: 65px;
  }
  .container-tela-desconhece-content > .container-logo .logo-flex {
    height: 25px;
  }
  .container-tela-desconhece-content > .container-logo p {
      font-size: 16px;
  }
  
