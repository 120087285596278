.container-error-modal {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-error-modal {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    width: 90%;
    max-width: 350px;
    min-height: 300px;
    max-height: 80vh;
    border-radius: 5px;
    padding: 25px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    animation: open 0.4s ease-in-out;
}

.error-modal-message {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    color: #252220;
}

.error-modal-button {
    border: none;
    outline: none;
    min-width: 125px; 
    text-align: center;
    background: #EC7000;
    font-family: museo700;
    color: white;
    border-radius: 10px;
    font-weight: bold;
    padding: 12px 16px;
}

.error-modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-modal-image img {
    display: flex;
    height: 75px;
}
.error-modal-title {
    font-size: 35px;
    color: #EC7000;
    margin-top: -36px;
}

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }