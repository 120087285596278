.container {
  min-height: 100vh;
  background-image: url("../../imgs/plano-fundo-splash.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container > .container-body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-body > .body-content {
  color: #fff;
  max-width: 885px;
  text-align: center;
  margin-bottom: -130px;
}
.body-content > h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.body-content > p {
  font-size: 28px;

  text-align: center;
  font: normal normal normal 32px;
  letter-spacing: 0px;
  opacity: 1;
}

@media (max-width: 480px) {
  .body-content > h1 {
    font-size: 25px;
  }

  .body-content > p {
    font-size: 20px;
  }
}
