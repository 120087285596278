.container-tela-cpf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  background: url("../../imgs/plano-fundo-consulta-cpf.jpg") no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container-tela-cpf-information {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
}

.container-tela-cpf-information > .container-tela-cpf-benefits {
  margin-top: 24px;
  margin-bottom: 16px;
  padding-left: 36px;
  padding-right: 36px;
}
.container-tela-cpf-benefits > h1 {
  margin: 0;
}
.container-tela-cpf-benefits > p {
  max-width: 770px;
  font-size: 24px;
  color: #707070;
}
.container-tela-cpf-benefits-items {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.container-tela-cpf-benefits-items .container-group-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: start;
}

.container-tela-cpf-benefits-items .benefits-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 230px;
  padding: 16px;
}

.benefits-item div {
  margin-bottom: 16px;
}

.benefits-item div img {
  width: 110px;
}
.benefits-item p {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 16px;
  padding-bottom: 20px;
}
.benefits-item span {
  font-size: 14px;
  margin-bottom: 16px;
}

.container-tela-cpf-information > .container-tela-cpf-phases {
  margin-top: 24px;
  margin-bottom: 16px;
  padding-left: 36px;
  padding-right: 36px;
}
.container-tela-cpf-phases > h1 {
  margin: 0;
}
.container-tela-cpf-phases > p {
  font-size: 24px;
  color: #707070;
}
.container-tela-cpf-phases-items {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}
.container-tela-cpf-phases-items > .phases-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 330px;
  padding: 16px;
  position: relative;
}

.phases-item div {
  border-radius: 30px;
  border: 3.5px solid #ec7000;
  padding: 16px;
  margin-top: 20px;
}

.phases-item img {
  width: 100%;
}
.phases-item p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 16px;
  color: #ec7000;
}
.phases-item span {
  font-size: 14px;
  margin-bottom: 16px;
}

.container-tela-cpf-whatsapp-session {
  background: #e8e8e8;
  margin-bottom: 50px;
}
.container-tela-cpf-whatsapp-session > .container-whatsapp-session {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  color: #252220;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.container-whatsapp-session > .container-whatsapp-session-img {
  border-radius: 40px;
  max-width: 437px;
  margin: 18px;

  height: auto;
  position: relative;
}
.container-whatsapp-session-img img {
  object-fit: cover;
  width: 100%;
}

.container-whatsapp-session > .container-whatsapp-session-text {
  margin: 16px;
  max-width: 500px;
}
.container-whatsapp-session-text > p {
  padding-bottom: 24px;
}

.container-whatsapp-session-text > h1 {
  margin-top: 0;
}

.btn-negociar-whatsapp {
  background-color: #25d366;
  color: #fff;
  font-weight: bold;
  padding: 12px 32px;
  text-decoration: none;
  border-radius: 8px;
  border: none;
  white-space: nowrap;
}

.container-tela-cpf-float-bottons > .btn-negociar {
	background-color: #ec7000;
	border-radius: 8px;
	padding: 12px 32px;
	box-shadow: -2px 5px 9px -1px rgba(0, 0, 0, 0.4);
	position: fixed;
	bottom: 16px;
	left: 50%;
	transform: translateX(-50%);
	margin: 0 auto;
	border: none;
	color: white;
	font-weight: bold;
	font-size: 16px;
	transition: all 0.5s ease;
	opacity: 0;
  }

.container-tela-cpf-items {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  animation: openBoleto 2s;
  max-width: 1320px;
}

.container-tela-cpf-description {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  height: 100%;
  color: white;
  max-width: 440px;
  font-size: 25px;
  padding: 16px;
  font-weight: 100;
}

.tela-cpf-description-title {
  font-size: 30px;
  padding-bottom: 10px;
}
.tela-cpf-description-text {
  font-size: 25px;
}

.container-tela-cpf-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: #707070;
  padding-top: 16px;
  padding-bottom: 16px;
}

.container-tela-cpf-footer > .content-footer {
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  flex-wrap: wrap;
}

.container-tela-cpf-footer .footer-logo-flex {
  padding: 10px;
}
.container-tela-cpf-footer .footer-logo-flex > img {
  height: 50px;
}
.container-tela-cpf-footer .footer-logo-flex > p {
  white-space: nowrap;
  font-size: 12px;
}
.container-tela-cpf-footer .content-footer > p {
  max-width: 450px;
  font-size: 14px;
  padding: 10px;
}

.container-tela-cpf-img {
  background: white;
  border-radius: 15px;
  min-height: 100%;
  display: flex;
}

.container-tela-cpf-forms {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  padding: 25px 30px;
  box-shadow: -2px 5px 9px -1px rgb(0 0 0 / 40%);
  max-width: 350px;
}

.container-tela-cpf-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  gap: 16px;
  grid-gap: 16px;
}

.form-telacpf {
  border: solid 2px #b4b2b0;
  color: #707070;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container-tela-cpf-text {
  text-align: left;
}

.cabecalho-telacpf div {
  text-align: left;
  color: #252220;
}

.cabecalho-telacpf span {
  border-bottom: 5px solid #ec7000;
}

.tela-cpf-nao-conheco {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  color: #707070;
  text-align: center;
  text-decoration: underline;
  background-color: transparent;
  width: 100%;
  white-space: nowrap;
}

.tela-cpf-acessar {
  font-size: 16px;
  font-family: Roboto-Regular;
  border: 1px solid #ec7000;
  outline: none;
  color: #ec7000;
  border-radius: 5px;
  padding: 12px 16px;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  white-space: nowrap;
}

.cpf {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  background: transparent;
  text-align: right;
  color: #707070;
}

.cpf::placeholder {
  font-family: Roboto-Regular;
  color: #707070;
}

.cnpj {
  font-family: Roboto-Regular;
  border: none;
  outline: none;
  background: transparent;
  text-align: right;
  color: #707070;
}

.label-cpf {
  font-family: Roboto-Regular;
}

.label-cnpj {
  font-family: Roboto-Regular;
}

.Button-cpf {
  font-family: museo;
  background-color: #ec7000;
  text-align: center;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  padding: 12px 16px;
  width: 100px;
}

.row {
  animation: openBoleto 2s;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col {
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
  width: 100%;
  *zoom: 1;
  *display: inline;
}

.col-tam-img {
  width: 85%;
}

.col-tam-txt {
  width: 75%;
}

.input {
  border: solid 1px whitesmoke;
  border-radius: 50px;
  color: white;
  width: 68%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 1em;
  align-items: center;
  margin-bottom: 2%;
  font-family: museo700;
}

::-webkit-input-placeholder {
  color: white;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}

:-ms-input-placeholder {
  color: white;
}

.label_cnpj {
  font-size: 0.8em;
}

.ajust-img-aling {
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-title {
  width: 100%;
}

.border-barra-title {
  height: auto;
  width: 70%;
  padding-top: 10%;
  padding-bottom: 10%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 20%;
  border: 2px solid white;
  border-radius: 15px;
  background-color: white;
}

.textoCPF {
  color: #707070;
  font-family: Roboto-Regular;
  font-size: 1em;
  word-break: normal;
}
.texto-cpf-description {
  color: #707070;
  font-family: Roboto-Regular;
  font-size: 0.85em;
  word-break: normal;
}

.modal {
  position: absolute;
  width: 400 !important;
}

.cabecalho-telacpf {
  text-align: center;
  color: white;
  font-family: Roboto-Bold;
  font-weight: 900;
  font-size: 1.7em;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
}

@media only screen and (min-width: 720px) {
  .container-tela-cpf {
    height: 100vh;
    min-height: 450px !important;
  }

  .container-tela-cpf-items {
    width: 100%;
  }

  .container-tela-cpf-footer {
    width: 100%;
  }

  .container-tela-cpf-img {
    border-radius: 15px;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
    margin: 10px;
  }

  .container-tela-cpf-forms {
    min-width: 320px;
    width: 380px;
    margin: 0 16px -30px 16px;
  }

  .form-telacpf {
  }

  .tela-cpf-nao-conheco {
  }

  .tela-cpf-acessar {
  }

  .cpf {
    font-size: 1.2em;
    width: 60px;
  }

  .cpf::placeholder {
    letter-spacing: 0px;
  }

  .cnpj {
    font-size: 1em;
    width: 35px;
  }

  .label-cpf {
    font-size: 1.2em;
  }

  .label-cnpj {
    font-size: 1em;
  }
}

@media only screen and (max-width: 720px) {
  .container-tela-cpf {
    min-height: 610px !important;
  }

  .container-tela-cpf-items {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    animation: openBoleto 2s;
  }

  .container-tela-cpf-footer {
    width: 100%;
  }

  .container-tela-cpf-img {
    border-radius: 15px;
    height: 100%;
    padding: 20px;
    margin: 10px;
  }

  .container-tela-cpf-description {
    max-width: 410px;
  }

  .container-tela-cpf-forms {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -230px;
  }

  .form-telacpf {
  }

  .tela-cpf-nao-conheco {
  }

  .tela-cpf-acessar {
  }

  .cpf {
    font-size: 1.2em;
    width: 60px;
  }

  .cpf::placeholder {
    letter-spacing: 0.1px;
  }

  .cnpj {
    font-size: 1em;
    width: 35px;
  }

  .label-cpf {
    font-size: 1.2em;
  }

  .label-cnpj {
    font-size: 1em;
  }

  .container-tela-cpf-information {
    margin-top: 200px;
  }
}

@media (max-width: 430px) {
  .container-tela-cpf {
    min-height: 760px !important;
  }
}

@media (max-width: 400px) {
  .container-tela-cpf-forms {
    margin-top: 4px !important;
  }
}

@media (max-width: 480px) {
  .tela-cpf-description-title {
    font-size: 25px;
  }
  .tela-cpf-description-text {
    font-size: 20px;
  }
  
  .container-tela-cpf-forms {
    margin-bottom: -280px;
  }
  .modal-buttons-options {
    display: flex;
    flex-direction: column;
  }
  .modal-buttons-options > button {
    width: 100%;
    margin-top: 8px;
  }
  .container-tela-cpf-information {
    margin-top: 245px;
  }

  .container-tela-cpf-benefits > h1 {
    font-size: 25px;
  }
  .container-tela-cpf-benefits > p {
    font-size: 20px;
  }

  .container-tela-cpf-phases > h1 {
    font-size: 25px;
  }
  .container-tela-cpf-phases > p {
    font-size: 20px;
  }
  .container-whatsapp-session-text > h1 {
    font-size: 25px;
  }

  .MuiDialog-paperWidthSm-9 {
    margin: 10px !important;
  }

  .MuiDialogContentText-root-84 {
    white-space: normal !important;
    text-align: center !important;
  }
}

@media (min-width: 480px) {
  .col-2 {
    width: 30%;
  }
  .col-tam-txt {
    width: 30%;
    font-size: 19px;
  }
  .ajust-img-aling {
    display: flex;
    justify-content: flex-end !important;
    margin-right: 15px;
    align-items: flex-end;
  }
}

@media (max-width: 495px) {
  .container-tela-cpf-benefits-items .benefits-item {
    max-width: 260px !important;
  }
}

@media (max-width: 600px) {
  .input {
    width: 100%;
  }
  .Container-cpf {
    justify-content: flex-start;
  }
  .border-barra-title {
    margin-top: 35%;
  }
  .container-form-telacpf {
    width: 100%;
  }

  .container-tela-cpf-benefits-items .benefits-item {
    max-width: 180px;
  }

  .benefits-item p {
    padding-bottom: 0;
  }
}

@media (max-width: 1200px) {
  .input {
    width: 90%;
  }
  .container-form-telacpf {
    width: 90%;
  }
}

@media (max-width: 1320px) {
  .input {
    width: 90%;
  }

  .button-cpf-cliente {
    margin-left: -38%;
  }

  .container-form-telacpf {
    width: 90%;
  }

  .button-cpf-cliente {
    margin-left: -38%;
  }
}

@media (max-height: 747px) {
  .container-tela-cpf-forms {
    margin-top: 45px;
  }
}

@media (max-width: 575.98px) {
  .container-tela-cpf-float-bottons > .btn-negociar {
    left: 16px !important;
    transform: none;
  }
  .container-tela-cpf {
    min-height: 660px !important;
  }

  .container-tela-cpf-buttons {
    flex-direction: column;
  }
}

@media (max-width: 991.98px) {
  .tela-cpf-description-title {
    font-size: 27px;
  }
  .tela-cpf-description-text {
    font-size: 22px;
  }
}
