@font-face {
	font-family: Roboto-Regular;
	src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: Roboto-Bold;
	src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
	font-family: museo;
	src: url('./fonts/MuseoSansCyrl.woff.ttf');
}
@font-face {
	font-family: museo700;
	src: url('./fonts/museosanscyrl-700.ttf');
}

@font-face {
	font-family: museo900;
	src: url('./fonts/Museo900-Regular.otf');
}

body {
	margin: 0;
	font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	/*background: url('./imgs/background-02.jpg') no-repeat;*/
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width:6px!important;
    height:6px!important;
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #acafbb;
  }
  ::-webkit-scrollbar-thumb:hover {
	background-color: #acafbb;
  }
  ::-webkit-scrollbar-track{
    background-color: transform
  }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
	font-family: Roboto-Regular;
	cursor: pointer;
}

@keyframes openBoleto {
	0% {
		opacity: 0.6;
		transform: translateY(10%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

.container-tela-cpf-float-bottons {
	position: relative;
	max-width: 1320px;
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
  }
  
  .container-tela-cpf-float-bottons > .btn-whatsapp {
	background-color: #25d366;
	border-radius: 50%;
	padding: 12px;
	box-shadow: -2px 5px 9px -1px rgba(0, 0, 0, 0.4);
	position: fixed;
	bottom: 16px;
	align-self: right;
	border: none;
	margin-right: 16px;
	transition: all 0.5s ease;

  }
  
  .container-tela-cpf-float-bottons > .btn-whatsapp > img {
	width: 32px;
	margin-bottom: -5px;
  }
  

