.logo-erro {
  width: 45%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.container-erro {
  background-color: #f16112;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.container-erro-content {
  background-color: #fff;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.container-erro-items {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container-erro-label {
  color: #252220;
  font-weight: bold;
  font-size: 1.5em;
}

.container-erro-content > .container-logo {
  display: flex;
  align-items: center;
  justify-content: space-around;

  color: #484d4f;
  font-size: 12px;
}

.container-erro-content > .container-logo > .divider {
  background-color: #484d4f;
  width: 1px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.container-erro-content > .container-logo > .logo-itau {
  height: 65px;
}
.container-erro-content > .container-logo .logo-flex {
  height: 25px;
}
.container-erro-content > .container-logo p {
    font-size: 16px;
}
