.container-tela-desconhece {
    background-color: #f16112;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.container-tela-desconhece-content {
    background-color: #fff;
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tela-desconhece-logo {
    width: 45%;
    min-width: 250px;
}

.tela-desconhece-items {
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -35px;
    height: 76px;
}

.tela-desconhece-success {
    display: flex;
    justify-content: center;
}

.tela-desconhece-success img {
    width: 125px;
}

.tela-desconhece-text {
    color: #252220;
    font-size: 1.4em;
}

.container-tela-desconhece-content > .container-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
  
    color: #484d4f;
    font-size: 12px;
  }
  
  .container-tela-desconhece-content > .container-logo > .divider {
    background-color: #484d4f;
    width: 1px;
    height: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .container-tela-desconhece-content > .container-logo > .logo-itau {
    height: 65px;
  }
  .container-tela-desconhece-content > .container-logo .logo-flex {
    height: 25px;
  }
  .container-tela-desconhece-content > .container-logo p {
      font-size: 16px;
  }
  