.LinhaUm-boleto-gerado {
	display: flex;
	flex-direction: column;
	padding: 5%;
}

.linha2-boleto-gerado {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.subTitle-boleto-gerado {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	overflow-x: hidden;
	font-family: museo;
}

.botao-copiar-boleto-gerado {
	background-color: #EC7000;
	padding-top: 5%;
	padding-bottom: 5%;
	border: none;
	outline: none;
	text-align: center;
	color: white;
	border-radius: 5px;
}

.textoBottom-boleto-gerado {
	font-size: 0.7em;
	font-family: Roboto-Regular;
	text-align: right;
}

.Title-gerado-boleto {
	font-size: 1em;
	text-align: left;
	font-family: museo700;
	white-space: nowrap;
}

.Container-boleto {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.itensBottom {
	width: 55%;
	text-align: right;
}

.Container-boleto {
	background: url('../../imgs/background-02.jpg') center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 100vh;
}

.Button-boleto-gerado {
	/* font-family: museo; */
	background-color: #EC7000;
	margin-top: 5px;
	margin-bottom: 15px;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	text-align: center;
	word-break: unset;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
}

@media only screen and (max-width: 565px) {

	.Button-boleto-gerado {
		width: 90%;
	}

	.LinhaUm-boleto-gerado {
		display: flex;
		flex-direction: column;
		padding: 5%;
	}

	.linha2-boleto-gerado {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.subTitle-boleto-gerado {
		font-weight: 100;
		color: black;
		font-size: 1em;
		white-space: nowrap;
		overflow-x: hidden;
		font-family: museo;
	}

	.botao-copiar-boleto-gerado {
		padding-top: 5%;
		padding-bottom: 5%;
		border: none;
		outline: none;
		text-align: center;
		color: white;
		border-radius: 5px;
		font-size: 0.7em;
	}

	.textoBottom-boleto-gerado {
		font-size: 0.70em;
		font-family: Roboto-Regular;
		text-align: right;
	}

	.itensBottom {
		width: 60%;
		text-align: right;
	}
	@media (max-width: 400px) and (min-height: 650px) {
		.itensBottom {
			width: 65%;
			text-align: right;
		}
	
		.textoBottom-boleto-gerado {
			font-size: 0.60em;
			font-family: Roboto-Regular;
			text-align: right;
		}
	}
}
@media only screen and (max-width: 360px) {
	.Container-boleto {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: left;
	}

	.Container-boleto {
		background: url('../../imgs/background-02.jpg');
		background-size: cover;
		position: initial;
		width: 100%;
		height: 100%;
	}
}

@media only screen and (max-width: 475px) {
	.Container-boleto {
		width: 100%;
		height: -1%;
		position: initial;
	}

}

#inativo {
	display: none;
}